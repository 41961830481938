<script setup>
const props = defineProps({
  linkObject: {
    type: Object,
    default: () => ({}),
  },
  target: {
    type: String,
    default: undefined,
  },
  rel: {
    type: String,
    default: undefined,
  },
  url: {
    type: String,
    default: undefined,
  },
});
const { asLink } = usePrismic();
const computedLink = computed(() => {
  if (props.url) {
    return asLink({
      link_type: "Web",
      url: props.url,
    });
  }
  return asLink(props.linkObject);
});
</script>

<template>
  <a v-if="computedLink" :href="computedLink" v-bind="$attrs" :target="target" :rel="rel">
    <slot />
  </a>
</template>
