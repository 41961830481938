import linkResolver from "./linkResolver";

export default {
  heading1: ({ children }) => `<h1>${children}</h1>`,
  heading2: ({ children }) => `<h2>${children}</h2>`,
  heading3: ({ children }) => `<h3>${children}</h3>`,
  heading4: ({ children }) => `<h4>${children}</h4>`,
  heading5: ({ children }) => `<h5>${children}</h5>`,
  heading6: ({ children }) => `<h6>${children}</h6>`,
  paragraph: ({ children }) => `<p>${children}</p>`,
  preformatted: ({ node }) => `<pre>${JSON.stringify(node.text)}</pre>`,
  strong: ({ children }) => `<strong>${children}</strong>`,
  em: ({ children }) => `<em>${children}</em>`,
  listItem: ({ children }) => `<li>${children}</li>`,
  oListItem: ({ children }) => `<li>${children}</li>`,
  list: ({ children }) => `<ul>${children}</ul>`,
  oList: ({ children }) => `<ol>${children}</ol>`,
  image: ({ node }) => {
    let url = node.url.replace(/\?.*/g, "") + "?auto=format,compress&w=800&fm=webp&q=80";

    const linkUrl = node.linkTo ? linkResolver(node.linkTo) : null;
    const linkTarget = node.linkTo && node.linkTo.target ? `target="${node.linkTo.target}" rel="noopener"` : "";
    const wrapperClassList = [node.label || "", "block my-10 w-full"];
    const caption = node.alt ? `<figcaption class="block mt-1 text-gray-600 text-center">${node.alt}</figcaption>` : "";
    const sizes = "(max-width: 720px) 100vw, (max-width: 960px) 100vw, 100vw";
    const srcset = `${url}?auto=format,compress&q=80&fm=webp&w=540 540w, ${url}?auto=format,compress&q=80&fm=webp&w=720 720w, ${url}?auto=format,compress&q=80&fm=webp&w=960 960w, ${url}?auto=format,compress&q=80&fm=webp&w=1080 1080w, ${url}?auto=format,compress&q=80&fm=webp&w=1440 1440w, ${url}?auto=format,compress&q=80&fm=webp&w=1920 1920w`;
    const img =
      `<img loading="lazy" class="object-cover w-full" src="${url}" alt="${node.alt ? node.alt : ""}" sizes="${sizes}" srcset="${srcset}"/>` +
      caption;

    return `
        <figure class="${wrapperClassList.join(" ")}">
          ${linkUrl ? `<a ${linkTarget} href="${linkUrl}">${img}</a>` : img}
        </figure>
      `;
  },
  embed: ({ node }) => `
        <div data-oembed="${node.oembed.embed_url}"
          data-oembed-type="${node.oembed.type}"
          data-oembed-provider="${node.oembed.provider_name}"
          ${label(node)}>
          ${node.oembed.html}
        </div>
      `,
  hyperlink: ({ node, children }) => {
    const target = node.data.target ? `target="${node.data.target}" rel="noopener"` : "";
    const url = linkResolver(node.data);
    return `<a ${target} href="${url}">${children}</a>`;
  },
  label: ({ node, children }) => {
    return `<span class="${node.data.label}">${children}</span>`;
  },
  span: ({ text }) => (text ? text : ""),
};
