<script setup>
onMounted(() => {
  useFlowbite(() => {});
});
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
