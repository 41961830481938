// Exclude from page generation
export const excludedRoutes = [/\/components(\/).*?$/];

// Generate page, exclude from sitemap
export const excludedSitemap = [/\/preview(\/)?$/, /\/not-found(\/)?$/, /\/404(\/)?$/];

export default {
  home_page: {
    "ko-kr": "/",
    "de-de": "/de/",
  },
  blog_hub: {
    "ko-kr": "/blog",
    "de-de": "/de/blog",
  },
  blog_article: {
    "ko-kr": "/blog/articles",
    "de-de": "/de/blog/articles",
  },
  blog_category: {
    "ko-kr": "/blog",
    "de-de": "/de/blog",
  },
  contact: {
    "ko-kr": "/contact",
    "de-de": "/de/kontakt",
  },
  faq: {
    "ko-kr": "/faq",
    "de-de": "/de/faq",
  },
  about_us: {
    "ko-kr": "/about-us",
    "de-de": "/de/ueber-uns",
  },
  error_page: {
    "ko-kr": "/not-found",
    "de-de": "/de/not-found",
  },
  properties: {
    "ko-kr": "/properties",
    "de-de": "/de/properties",
  },
};
