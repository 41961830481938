<script setup>
import footer from "@/scripts/prismic/footer.json";
import { mdiFacebook } from "@mdi/js";

const { localeProperties } = useI18n();
const footerData = computed(() => footer[localeProperties.value.language]);
const localePath = useLocalePath();
const socials = ["https://twitter.com", "https://facebook.com"];
</script>

<template>
  <footer
    class="border-t gap-20 -md:gap-10 -md:flex-col flex border-gray-200 py-10 px-6 -sm:px-4 max-w-[1328px] mx-auto"
  >
    <aside class="max-w-[320px]">
      <PLink :url="localePath('/')">
        <PImage :image-object="footerData.logo" class="h-[42px]" sizes="xs:200px" />
      </PLink>
      <RichText :text="footerData.description" class="text-gray-500 mt-4" />

      <ul class="my-4">
        <li>
          <PLink>
            <ZButton rounded size="xs" color="dark"><ZIcon :path="mdiFacebook" /></ZButton>
          </PLink>
        </li>
      </ul>
    </aside>

    <section class="flex w-full -sm:flex-col gap-10">
      <div class="flex-1">
        <RichText :text="footerData.menu1_title" class="text-gray-800 font-bold" />
        <ul class="flex flex-col gap-1 mt-4">
          <li v-for="menu in footerData.menu1">
            <PLink :link-object="menu.link" class="text-gray-500 hover:text-orange-500">{{ menu.link_text }}</PLink>
          </li>
        </ul>
      </div>

      <div class="flex-1">
        <RichText :text="footerData.menu2_title" class="text-gray-800 font-bold" />
        <ul class="flex flex-col gap-1 mt-4">
          <li v-for="menu in footerData.menu2">
            <PLink :link-object="menu.link" class="text-gray-500 hover:text-orange-500">{{ menu.link_text }}</PLink>
          </li>
        </ul>
      </div>
    </section>
  </footer>
</template>
