<script setup lang="ts">
const props = defineProps({
  type: {
    type: String as PropType<"button" | "submit" | "reset">,
    default: "button",
  },
  disabled: {
    type: Boolean,
    default: undefined,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String as PropType<"primary" | "dark" | "white">,
    default: "primary",
  },
  size: {
    type: String as PropType<"xs" | "sm" | "md" | "lg" | "xl">,
    default: "md",
  },
  loading: {
    type: Boolean,
    default: false,
  },
  noBorder: {
    type: Boolean,
    default: false,
  },
  url: {
    type: String,
    default: undefined,
  },
  linkObject: {
    type: Object,
    default: undefined,
  },
});

const _colorMap = {
  primary: "text-white bg-primary-500 hover:bg-primary-600 focus:ring-4 focus:ring-primary-200 disabled:bg-gray-300",
  dark: "text-white bg-gray-900 hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 disabled:bg-gray-300",
  white:
    "text-black bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 disabled:bg-gray-300 shadow-sm border-2 border-gray-100",
};

const _sizeMap = computed(() => {
  let size = {
    xs: "px-3 py-2",
    sm: "px-3 py-2",
    md: "px-5 py-2.5",
    lg: "px-6 py-3.5",
    xl: "px-6 py-3.5",
  };

  if (props.rounded) {
    size = {
      xs: `p-2 !rounded-[50%]`,
      sm: `p-3.5 !rounded-[50%]`,
      md: `p-4 !rounded-[50%]`,
      lg: `p-5 !rounded-[50%]`,
      xl: `p-6 !rounded-[50%]`,
    };
  }

  return {
    xs: `${size.xs} text-xs`,
    sm: `${size.sm} text-sm`,
    md: `${size.md} text-sm`,
    lg: `${size.lg} text-base`,
    xl: `${size.xl} text-md`,
  };
});

const _class = computed(() => {
  return `${_colorMap[props.color]} ${_sizeMap.value[props.size]}`;
});

const { asLink } = usePrismic();
const computedLink = computed(() => {
  if (!props.url && !props.linkObject) {
    return undefined;
  }

  if (props.url) {
    return asLink({
      link_type: "Web",
      url: props.url,
    });
  }
  return asLink(props.linkObject);
});
</script>

<template>
  <component
    :is="computedLink ? 'a' : 'button'"
    :type="computedLink ? undefined : type"
    :disabled="computedLink ? undefined : disabled"
    :href="computedLink"
    class="flex font-bold items-center gap-2 text-center justify-center rounded-lg focus:outline-none disabled:cursor-not-allowed"
    :class="[_class, { '!border-0 !shadow-none': noBorder }]"
  >
    <slot />
    <ZSpinner v-if="loading" :size="size" />
  </component>
</template>
