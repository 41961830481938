<script setup>
const props = defineProps({
  imageObject: {
    type: Object,
    default: () => ({}),
  },
  sizes: {
    type: String,
    default: () => "", // "xs:100vw sm:100vw md:50vw lg:400px"
  },
  quality: {
    type: Number,
    default: () => 80,
  },
  fit: {
    type: String,
    default: () => "cover", // cover, contain, fill, inside, outside
  },
  lazy: {
    type: Boolean,
    default: false,
  },
  preload: {
    type: Boolean,
    default: false,
  },
});
const imageAlt = computed(() => props.imageObject?.alt);
const normalize = (text) => {
  return text.replace(/rect=.*?&|h=.*?&/g, "").replace(/\?auto=format,compress\?/g, "?auto=format,compress&");
};

const $img = useImage();
const image = computed(() => {
  if (!props.imageObject?.url) return "";
  if (!props.sizes) {
    return {
      src: normalize(props.imageObject.url),
    };
  }

  const img = $img.getSizes(props.imageObject.url, {
    modifiers: {
      quality: props.quality,
      fit: props.fit,
      format: "webp",
    },
    sizes: props.sizes,
  });

  return {
    src: normalize(img.src),
    sizes: normalize(img.sizes),
    srcset: normalize(img.srcset),
  };
});

useHead({
  link:
    !props.preload || !props.imageObject
      ? []
      : [
          {
            rel: "preload",
            href: image.value.src,
            as: "image",
            imagesrcset: image.value.srcset,
            imagesizes: image.value.sizes,
          },
        ],
});
</script>

<template>
  <img
    v-if="imageObject && imageObject.url"
    :src="image.src"
    :alt="imageAlt"
    :sizes="image.sizes"
    :srcset="image.srcset"
    :loading="lazy ? 'lazy' : undefined"
  />
</template>
