<script setup lang="ts">
const props = defineProps({
  type: {
    type: String,
    default: "default",
  },
  path: { type: String, required: true },
  size: { type: [String, Number], default: 24 },
  viewbox: String,
  flip: {
    type: String,
    validator: (value) => ["horizontal", "vertical", "both", "none"].includes(value),
  },
  rotate: { type: Number, default: 0 },
});

const styles = computed(() => {
  return {
    "--sx": ["both", "horizontal"].includes(props.flip) ? "-1" : "1",
    "--sy": ["both", "vertical"].includes(props.flip) ? "-1" : "1",
    "--r": isNaN(props.rotate) ? props.rotate : props.rotate + "deg",
  };
});
</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" :style="styles">
    <path :d="path" />
  </svg>
</template>

<style scoped>
svg {
  transform: rotate(var(--r, 0deg)) scale(var(--sx, 1), var(--sy, 1));
}

path {
  fill: currentColor;
}
</style>
