
// @ts-nocheck


export const localeCodes =  [
  "ko",
  "de"
]

export const localeLoaders = {
  "ko": [],
  "de": []
}

export const vueI18nConfigs = [
  () => import("../config/i18n/index.ts?hash=32c1c5c7&config=1" /* webpackChunkName: "@_config_i18n_index_32c1c5c7" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "@/config/i18n/index",
  "locales": [
    {
      "code": "ko",
      "language": "ko-kr",
      "name": "한국어"
    },
    {
      "code": "de",
      "language": "de-de",
      "name": "Deutsch"
    }
  ],
  "defaultLocale": "ko",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "https://dev.zweeback.com",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "index": {
      "ko": "/",
      "de": "/"
    },
    "blog/articles/[uid]": {
      "ko": "/blog/articles/[uid]",
      "de": "/blog/articles/[uid]"
    },
    "blog": {
      "ko": "/blog",
      "de": "/blog"
    },
    "properties": {
      "ko": "/properties",
      "de": "/properties"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "ko",
    "language": "ko-kr",
    "name": "한국어",
    "files": []
  },
  {
    "code": "de",
    "language": "de-de",
    "name": "Deutsch",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
