import domainPages from "@/config/router/index.js";

const getErrorPagePath = (lang) => {
  return domainPages.error_page[lang || "ko-kr"].concat("/").replace(/\/\//g, "/");
};

const getPagePath = ({ type, lang, uid }) => {
  const langsPages = domainPages[type];
  const path = langsPages ? langsPages[lang] : null;
  return path
    ? [path, uid]
        .filter((e) => e)
        .join("/")
        .concat("/") // add trailing slash
        .replace(/\/\//g, "/")
    : getErrorPagePath(lang);
};

export default (doc) => {
  const { url, link_type, isBroken, type, lang, uid } = doc;

  if (isBroken) {
    return getErrorPagePath(lang);
  }
  const shouldReturnUrl = url && ["Media", "Web"].includes(link_type);
  if (shouldReturnUrl) return url;

  return getPagePath({ type, lang, uid });
};
