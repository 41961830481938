import { defineNuxtPlugin } from '#app/nuxt'
import { LazyArticleCard, LazyArticleMain, LazyBlogHero, LazyBlogRelatedArticle, LazyEmptySection, LazyFaqDropdown, LazyMFooter, LazyMHeader, LazyPImage, LazyPLink, LazyPageBlogArticle, LazyPageBlogCategory, LazyPageBlogHub, LazyPageContact, LazyPageFaq, LazyPageHome, LazyPageNotFound, LazyPageProperties, LazyPropertyCard, LazyRichText, LazyZButton, LazyZIcon, LazyZInput, LazyZLabel, LazyZSearchAddress, LazyZSpinner, LazyZTextInput } from '#components'
const lazyGlobalComponents = [
  ["ArticleCard", LazyArticleCard],
["ArticleMain", LazyArticleMain],
["BlogHero", LazyBlogHero],
["BlogRelatedArticle", LazyBlogRelatedArticle],
["EmptySection", LazyEmptySection],
["FaqDropdown", LazyFaqDropdown],
["MFooter", LazyMFooter],
["MHeader", LazyMHeader],
["PImage", LazyPImage],
["PLink", LazyPLink],
["PageBlogArticle", LazyPageBlogArticle],
["PageBlogCategory", LazyPageBlogCategory],
["PageBlogHub", LazyPageBlogHub],
["PageContact", LazyPageContact],
["PageFaq", LazyPageFaq],
["PageHome", LazyPageHome],
["PageNotFound", LazyPageNotFound],
["PageProperties", LazyPageProperties],
["PropertyCard", LazyPropertyCard],
["RichText", LazyRichText],
["ZButton", LazyZButton],
["ZIcon", LazyZIcon],
["ZInput", LazyZInput],
["ZLabel", LazyZLabel],
["ZSearchAddress", LazyZSearchAddress],
["ZSpinner", LazyZSpinner],
["ZTextInput", LazyZTextInput],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
