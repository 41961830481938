import revive_payload_client_lPhbIFzpn9 from "/opt/build/repo/front/node_modules/.pnpm/nuxt@3.12.4_rollup@4.21.0_sass@1.77.8_vite@5.4.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_HkRqjhuVB3 from "/opt/build/repo/front/node_modules/.pnpm/nuxt@3.12.4_rollup@4.21.0_sass@1.77.8_vite@5.4.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_cbRbKnDVEa from "/opt/build/repo/front/node_modules/.pnpm/nuxt@3.12.4_rollup@4.21.0_sass@1.77.8_vite@5.4.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_KxS2ONHXQ0 from "/opt/build/repo/front/node_modules/.pnpm/nuxt@3.12.4_rollup@4.21.0_sass@1.77.8_vite@5.4.2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_qzDeAQRL16 from "/opt/build/repo/front/node_modules/.pnpm/nuxt@3.12.4_rollup@4.21.0_sass@1.77.8_vite@5.4.2/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_QNK32usuuN from "/opt/build/repo/front/node_modules/.pnpm/nuxt@3.12.4_rollup@4.21.0_sass@1.77.8_vite@5.4.2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_1KkjPNWNHN from "/opt/build/repo/front/node_modules/.pnpm/nuxt@3.12.4_rollup@4.21.0_sass@1.77.8_vite@5.4.2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_iwylMDWZ94 from "/opt/build/repo/front/node_modules/.pnpm/@pinia+nuxt@0.5.4_rollup@4.21.0_vue@3.4.38/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/front/.nuxt/components.plugin.mjs";
import prefetch_client_B3tcA1RQY0 from "/opt/build/repo/front/node_modules/.pnpm/nuxt@3.12.4_rollup@4.21.0_sass@1.77.8_vite@5.4.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_6IKRt6P7t0 from "/opt/build/repo/front/node_modules/.pnpm/@nuxtjs+i18n@8.5.0_rollup@4.21.0_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_pXl0JHqcFO from "/opt/build/repo/front/node_modules/.pnpm/@nuxtjs+i18n@8.5.0_rollup@4.21.0_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_CpzMjLGR1e from "/opt/build/repo/front/node_modules/.pnpm/@nuxtjs+prismic@3.3.2_rollup@4.21.0_vite@5.4.2_vue@3.4.38/node_modules/@nuxtjs/prismic/dist/runtime/plugin.client.js";
import plugin_nOQh8e9Oxr from "/opt/build/repo/front/node_modules/.pnpm/@nuxtjs+prismic@3.3.2_rollup@4.21.0_vite@5.4.2_vue@3.4.38/node_modules/@nuxtjs/prismic/dist/runtime/plugin.js";
export default [
  revive_payload_client_lPhbIFzpn9,
  unhead_HkRqjhuVB3,
  router_cbRbKnDVEa,
  payload_client_KxS2ONHXQ0,
  navigation_repaint_client_qzDeAQRL16,
  check_outdated_build_client_QNK32usuuN,
  chunk_reload_client_1KkjPNWNHN,
  plugin_vue3_iwylMDWZ94,
  components_plugin_KR1HBZs4kY,
  prefetch_client_B3tcA1RQY0,
  switch_locale_path_ssr_6IKRt6P7t0,
  i18n_pXl0JHqcFO,
  plugin_client_CpzMjLGR1e,
  plugin_nOQh8e9Oxr
]