<script setup lang="ts">
import navbar from "@/scripts/prismic/navbar.json";
import { mdiAccountOutline, mdiMenu, mdiWindowClose } from "@mdi/js";
import { vOnClickOutside } from "@vueuse/components";

const { localeProperties } = useI18n();
const navData = computed(() => navbar[localeProperties.value.language]);
const localePath = useLocalePath();
const showMobileMenu = ref(false);
const toggleMobileMenu = (open: boolean) => {
  showMobileMenu.value = open;
  if (open) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
  }
};
</script>

<template>
  <header>
    <div class="h-[68px] max-w-[1328px] mx-auto px-6 -sm:px-4 flex justify-between items-center relative">
      <PLink :url="localePath('/')">
        <PImage :image-object="navData.logo" class="h-[32px]" sizes="xs:200px" />
      </PLink>

      <nav class="-md:hidden">
        <ul class="flex gap-2">
          <li v-for="menu in navData.menus">
            <PLink :link-object="menu.link" class="hover:text-orange-500 text-lg p-3">{{ menu.link_text }}</PLink>
          </li>
        </ul>
      </nav>

      <div class="flex gap-2 items-center">
        <ZButton no-border size="xs" rounded color="white" aria-label="Login">
          <ZIcon :path="mdiAccountOutline" />
        </ZButton>
        <ZButton size="md" color="dark" class="-sm:hidden">{{ $t("header.postProperty") }}</ZButton>
        <ZButton no-border @click="() => toggleMobileMenu(true)" class="md:hidden" color="white" size="xs">
          <ZIcon :path="mdiMenu" />
        </ZButton>
      </div>

      <!-- mobile menu -->
      <Transition name="fade">
        <div v-show="showMobileMenu" class="absolute left-0 top-0 z-[999] w-screen h-screen bg-black bg-opacity-20">
          <div class="right-0 absolute bg-white w-[300px] p-2.5" v-on-click-outside="() => toggleMobileMenu(false)">
            <div class="flex justify-end">
              <button @click="() => toggleMobileMenu(false)" class="p-3 mr-1" aria-label="close mobile menu text-black">
                <ZIcon :path="mdiWindowClose" />
              </button>
            </div>

            <ul class="flex mt-4 flex-col z-[1000] h-screen gap-1">
              <li v-for="menu in navData.menus">
                <PLink :link-object="menu.link" class="block w-full hover:text-orange-500 text-lg px-4 py-2">
                  {{ menu.link_text }}
                </PLink>
              </li>
            </ul>
          </div>
        </div>
      </Transition>
    </div>
  </header>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
