<script setup lang="ts">
import errorPageData from "@/scripts/prismic/error.json";

const { localeProperties } = useI18n();
const data = computed(() => errorPageData[localeProperties.value.language]);
</script>

<template>
  <MHeader />
  <PageNotFound :data="data" />
  <MFooter />
</template>
